<template>
  <div class="content-wrap local_collect_invoice_view">
    <div class="content-select">
      <div class="select-content" v-if="isShowSearch">
        <el-form ref="selectFrom" label-width="100px" :model="search" inline>
          <el-form-item label="识别批次">
            <el-input v-model.trim="search.batchNo" placeholder="请输入识别批次" @keyup.enter.native="handleGetRecognizeFailureRecordData()" clearable />
          </el-form-item>
          <el-form-item label="识别状态">
            <el-select multiple v-model="search.statusList" collapse-tags placeholder="请选择" clearable>
              <el-option v-for="item in recognizeStatus" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="收票方式">
            <el-select v-model="search.channel" placeholder="请选择" clearable>
              <el-option v-for="item in invoiceChannels" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <span v-if="selectExpended">
            <el-form-item label="收票时间">
              <el-date-picker class="date-picker" v-model="search.receiveTime" type="date" value-format="yyyy-MM-dd" placeholder="选择日期" clearable> </el-date-picker>
            </el-form-item>
            <el-form-item label="发票代码">
              <el-input v-model.trim="search.invoiceCode" placeholder="请输入发票代码" @keyup.enter.native="handleGetRecognizeFailureRecordData()" clearable />
            </el-form-item>
            <el-form-item label="发票号码">
              <el-input v-model.trim="search.invoiceNo" placeholder="请输入发票号码" @keyup.enter.native="handleGetRecognizeFailureRecordData()" clearable />
            </el-form-item>
            <el-form-item label="乘车人">
              <el-input v-model.trim="search.rider" placeholder="请输入乘车人" @keyup.enter.native="handleGetRecognizeFailureRecordData()" clearable />
            </el-form-item>
            <el-form-item label="电子客票" class="train21No">
              <el-input v-model.trim="search.train21No" placeholder="请输入电子客票21位" @keyup.enter.native="handleGetRecognizeFailureRecordData()" clearable />
            </el-form-item>
            <el-form-item label="备注">
              <el-input maxlength="50" v-model.trim="search.remark" placeholder="请输入备注" @keyup.enter.native="handleGetRecognizeFailureRecordData()" clearable />
            </el-form-item>
            <el-form-item label="发件人">
              <el-input maxlength="50" v-model.trim="search.sender" placeholder="请输入发件人" @keyup.enter.native="handleGetRecognizeFailureRecordData()" clearable />
            </el-form-item>
            <el-form-item label="数电发票号码">
              <el-input v-model.trim="search.eInvoiceNo" placeholder="请输入数电发票号码" maxlength="20" @keyup.enter.native="handleGetRecognizeFailureRecordData()" clearable />
            </el-form-item>
          </span>
          <el-form-item>
            <select-button show-status @list-close="handleListClose" @list-show="handleListShow" @select="handleGetRecognizeFailureRecordData" @reset="handleReset"> </select-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="content-main">
      <div>
        <div class="main-top">
          <div class="top-left">
            <span>票据列表</span>
            <el-popover placement="right" width="80" trigger="click">
              <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选 </el-checkbox>
              <div class="part"></div>
              <el-checkbox v-for="(pv, pk) in columns" :label="pv.label" :key="pk" v-model="pv.visible" style="display: block; margin-top: 6px" @change="handleCheckedCitiesChange(pk, pv)" />
              <div slot="reference" class="main-top" v-if="isShowSearch">
                <img class="imgSet" src="@/assets/icon/set.png" />
                <span style="color: #1890ff">列设置</span>
              </div>
            </el-popover>
          </div>
          <div>
            <el-button type="primary" @click="afreshMatch">重新识别</el-button>
            <el-button type="primary" @click="handlerContinueToAdd">继续添加</el-button>
            <el-button type="primary" @click="handlerSubmit">提交</el-button>
          </div>
        </div>
        <div class="content-table">
          <el-table :tree-props="{ children: 'imageInvoiceLists', hasChildren: 'isExistList' }" :row-class-name="tableRowClassName" ref="failedTable" :data="tableData" v-loading="tableLoading" :header-cell-style="handleHeaderCellStyle" @selection-change="handleSelection" style="width: 100%" @expand-change="handleExpandChange">
            <el-table-column fixed="left" type="selection" width="50" />
            <el-table-column type="index" label="序号" width="50" />
            <el-table-column type="expand" width="20" v-if="this.from === 'local_collect_invoice' || this.from === 'scanner_collect_invoice'">
              <template slot-scope="scope">
                <el-table class="child_table" :data="tableData[`${scope.$index}`].imageInvoiceLists" style="margin: 0 10px; width: 100%" max-height="250">
                  <el-table-column type="selection" width="50" />
                  <el-table-column prop="invoiceCode" label="发票代码" width="120" show-overflow-tooltip />
                  <el-table-column prop="invoiceNo" label="发票号码" width="100" show-overflow-tooltip />
                  <el-table-column label="类型" width="100">发票清单</el-table-column>
                  <el-table-column label="张数" width="80">
                    <template slot-scope="scope"> {{ scope.row.pageIndex }}/{{ scope.row.pageSize }} </template>
                  </el-table-column>
                  <!-- <el-table-column prop="receiveTime" label="时间" width="180"></el-table-column> -->
                  <el-table-column prop="operate" label="操作" width="190">
                    <template slot-scope="scope">
                      <div class="operate-button">
                        <!-- <el-button type="text" @click="handleInvoiceMove(scope.row)">移动</el-button> -->
                        <el-button type="text" @click="handleInvoiceUpdatePopup(scope.row)">编辑</el-button>
                        <el-button type="text" @click="handleInvoiceRecognizeImage(scope.row)">影像</el-button>
                        <el-button type="text" @click="handleInvoiceDeletePopup(scope.row)">删除</el-button>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column />
                </el-table>
              </template>
            </el-table-column>
            <el-table-column v-if="columns['invoiceCode'].visible" prop="invoiceCode" :label="columns['invoiceCode'].label" width="120" show-overflow-tooltip />
            <el-table-column v-if="columns['invoiceNo'].visible" prop="invoiceNo" :label="columns['invoiceNo'].label" width="120" show-overflow-tooltip />
            <el-table-column prop="isExistList" :label="columns['isExistList'].label" width="120" :formatter="handleTableValueFormat" />
            <el-table-column v-if="columns['rider'].visible" prop="rider" :label="columns['rider'].label" width="100" :formatter="handleTableValueFormat" />
            <el-table-column v-if="columns['eInvoiceNo'].visible" prop="eInvoiceNo" :label="columns['eInvoiceNo'].label" width="190" :formatter="handleTableValueFormat" />
            <el-table-column v-if="columns['train21No'].visible" prop="train21No" :label="columns['train21No'].label" width="180" :formatter="handleTableValueFormat" />
            <el-table-column v-if="columns['batchNo'].visible" prop="batchNo" :label="columns['batchNo'].label" width="230" :formatter="handleTableValueFormat" />
            <el-table-column v-if="columns['batchIndex'].visible" prop="batchIndex" :label="columns['batchIndex'].label" width="150" :formatter="handleTableValueFormat" />
            <el-table-column v-if="columns['status'].visible" prop="status" :label="columns['status'].label" width="120">
              <template slot-scope="scope">
                <div :style="{ color: handleRecognizeStatusColor(scope.row.status) }">
                  <span>{{ handleValueToLabel('InvoiceRecognizedStatus', scope.row.status) }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column v-if="columns['receiveTime'].visible" prop="receiveTime" :label="columns['receiveTime'].label" width="200" :formatter="handleTableValueFormat" />
            <el-table-column v-if="columns['invoiceType'].visible" prop="invoiceType" :label="columns['invoiceType'].label" width="150" :formatter="handleTableValueFormat" />
            <el-table-column v-if="columns['channel'].visible" prop="channel" :label="columns['channel'].label" width="150" :formatter="handleTableValueFormat" />
            <el-table-column v-if="columns['invoiceTime'].visible" prop="invoiceTime" :label="columns['invoiceTime'].label" width="150" :formatter="handleTableValueFormat" />
            <el-table-column v-if="columns['amount'].visible" prop="amount" :label="columns['amount'].label" width="150" :formatter="handleTableValueFormat" />
            <el-table-column v-if="columns['taxAmount'].visible" prop="taxAmount" :label="columns['taxAmount'].label" width="150" :formatter="handleTableValueFormat" />
            <el-table-column v-if="columns['sumAmount'].visible" prop="sumAmount" :label="columns['sumAmount'].label" width="150" :formatter="handleTableValueFormat" />
            <el-table-column v-if="columns['checkCode'].visible" prop="checkCode" :label="columns['checkCode'].label" width="150" :formatter="handleTableValueFormat" />
            <el-table-column label="张数" width="80">
              <template slot="header" slot-scope="scope">
                <div>
                  <span>张数</span>
                  <el-tooltip placement="top">
                    <div slot="content" v-html="tips"></div>
                    <i class="el-icon-question icon"></i>
                  </el-tooltip>
                </div>
              </template>
              <template slot-scope="scope">
                <div :style="{ color: handleRecognizeCountColor(scope.row.unsubmittedPageCount, scope.row.submittedPageCount, scope.row.pageSize, scope.row.isExistList) }">
                  <span>{{ scope.row.unsubmittedPageCount }}/{{ scope.row.submittedPageCount }}/{{ scope.row.pageSize }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column v-if="columns['pageSize'].visible" prop="pageSize" :label="columns['pageSize'].label" width="80" :formatter="handleTableValueFormat" />
            <el-table-column v-if="columns['pageIndex'].visible" prop="pageIndex" :label="columns['pageIndex'].label" width="80" :formatter="handleTableValueFormat" />
            <el-table-column v-if="columns['orgName'].visible" prop="orgName" :label="columns['orgName'].label" :formatter="handleTableValueFormat" />
            <el-table-column v-if="columns['creatorName'].visible" prop="creatorName" :label="columns['creatorName'].label" width="120" :formatter="handleTableValueFormat" />
            <el-table-column v-if="columns['sender'].visible" prop="sender" :label="columns['sender'].label" :formatter="handleTableValueFormat" />
            <el-table-column v-if="columns['senderEmail'].visible" prop="senderEmail" :label="columns['senderEmail'].label" width="120" :formatter="handleTableValueFormat" />
            <el-table-column v-if="columns['remark'].visible" prop="remark" :label="columns['remark'].label" width="120" :formatter="handleTableValueFormat" />

            <el-table-column v-if="columns['operate'].visible" prop="operate" :label="columns['operate'].label" width="160" fixed="right">
              <template slot-scope="scope">
                <div class="operate-button">
                  <el-button type="text" @click="handleInvoiceUpdatePopup(scope.row)"> 编辑 </el-button>
                  <el-button type="text" @click="handleInvoiceRecognizeImage(scope.row)"> 影像 </el-button>
                  <el-button type="text" @click="handleInvoiceDeletePopup(scope.row)"> 删除 </el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="content-page">
        <el-pagination background :total="total" :page-size="search.size" :page-sizes="[15, 30, 50, 100]" layout="total, sizes, prev, pager, next" @size-change="handleSizeChange" @current-change="handleCurrentChange"> </el-pagination>
      </div>
    </div>

    <!-- 编辑弹窗 -->
    <el-dialog append-to-body title="编辑" fullscreen :visible.sync="invoiceUpdateVisible" :close-on-click-modal="false" :close-on-press-escape="false" @close="handleInvoiceUpdateCancel('invoiceUpdate')">
      <cusMessage />
      <!-- 查看影像 -->
      <div class="photo_box" v-show="isShowPhoto">
        <DragScaleImg :src="imgPre" v-if="imgPre" />
        <!-- <el-image style="width: 200px; cursor: pointer" :src="imgPre" :preview-src-list="invoiceImages"> </el-image> -->
      </div>
      <div class="tabs_box">
        <el-tabs v-model="activeName">
          <el-tab-pane label="主要信息" name="master" class="invoice-update">
            <el-form ref="invoiceUpdate" :model="invoiceUpdate" :rules="rules" label-position="top">
              <el-row type="flex" justify="space-between">
                <el-col :span="6">
                  <el-form-item label="发票类型">
                    <el-select v-model="invoiceUpdate.invoiceType" placeholder="请选择发票类型">
                      <el-option v-for="item in invoiceTypes" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="发票代码" prop="invoiceCode">
                    <el-input v-model="invoiceUpdate.invoiceCode" placeholder="请输入发票代码" maxlength="12" />
                  </el-form-item>
                </el-col>
                <el-col :span="6" v-if="!isInvoiceList">
                  <el-form-item label="发票号码" prop="invoiceNo">
                    <el-input v-model="invoiceUpdate.invoiceNo" placeholder="请输入发票号码" maxlength="8" />
                  </el-form-item>
                </el-col>
                <el-col :span="6" v-if="!isInvoiceList">
                  <el-form-item label="开票时间" placeholder="请输入开票时间">
                    <el-date-picker v-model="invoiceUpdate.invoiceTime" type="date" value-format="yyyy-MM-dd" :picker-options="pickerDisabled" placeholder="请选择时间"> </el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row type="flex" justify="space-between">
                <el-col :span="6" v-if="!isInvoiceList">
                  <el-form-item label="金额（不含税）" prop="amount">
                    <el-input v-model="invoiceUpdate.amount" placeholder="请输入金额" />
                  </el-form-item>
                </el-col>
                <el-col :span="6" v-if="!isInvoiceList">
                  <el-form-item prop="isExistList" label="是否有清单">
                    <el-select v-model="invoiceUpdate.isExistList">
                      <el-option v-for="item in isExistListArr" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6" v-if="!isInvoiceList">
                  <el-form-item label="发票状态" prop="invoiceStatus">
                    <el-select v-model="invoiceUpdate.invoiceStatus" placeholder="请选择发票状态">
                      <el-option v-for="item in invoiceStatusList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6" v-if="hasCheckCode && !isInvoiceList">
                  <el-form-item label="校验码后6位" prop="checkCode">
                    <el-input v-model="invoiceUpdate.checkCode" placeholder="请输入校验码后6位" maxlength="6" />
                  </el-form-item>
                </el-col>
                <el-col :span="6" v-if="isInvoiceList">
                  <el-form-item label="发票号码" prop="invoiceNo">
                    <el-input v-model="invoiceUpdate.invoiceNo" placeholder="请输入发票号码" maxlength="8" />
                  </el-form-item>
                </el-col>
                <el-col :span="6" v-if="isInvoiceList">
                  <el-form-item label="总页数" prop="pageSize">
                    <el-input v-model="invoiceUpdate.pageSize" placeholder="请输入总页数" maxlength="8" />
                  </el-form-item>
                </el-col>
                <el-col :span="6" v-if="isInvoiceList">
                  <el-form-item label="当前页码" prop="pageIndex">
                    <el-input v-model="invoiceUpdate.pageIndex" placeholder="请输入当前页码" maxlength="8" />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row type="flex" justify="space-between">
                <el-col :span="6">
                  <el-form-item label="备注">
                    <el-input v-model="invoiceUpdate.remark" placeholder="请输入" maxlength="50" />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="数电发票号码">
                    <el-input v-model="invoiceUpdate.eInvoiceNo" placeholder="请输入" maxlength="20" />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item> </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item> </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="审核信息" name="slave" class="invoice-update">
            <el-form ref="invoiceUpdate" :model="invoiceUpdate" :rules="rules" label-position="top">
              <el-row type="flex" justify="space-between">
                <el-col :span="6">
                  <el-form-item label="开票人" prop="payer">
                    <el-input v-model="invoiceUpdate.payer" placeholder="请输入开票人" />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="复核人" prop="auditor">
                    <el-input v-model="invoiceUpdate.auditor" placeholder="请输入复核人" />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="收款人" prop="payee">
                    <el-input v-model="invoiceUpdate.payee" placeholder="请输入收款人" />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="是否盖章" prop="invoiceSeal">
                    <el-select v-model="invoiceUpdate.invoiceSeal">
                      <el-option v-for="item in invoiceSeals" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row type="flex" justify="space-between">
                <el-col :span="6">
                  <el-form-item label="印刷代码" prop="printedBrushCode">
                    <el-input v-model="invoiceUpdate.printedBrushCode" placeholder="请输入印刷代码" maxlength="12" />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="打印代码" prop="printedCode">
                    <el-input v-model="invoiceUpdate.printedCode" placeholder="请输入打印代码" maxlength="12" />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="印刷号码" prop="printedBrushNo">
                    <el-input v-model="invoiceUpdate.printedBrushNo" placeholder="请输入印刷号码" maxlength="8" />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="打印号码" prop="printedNo">
                    <el-input v-model="invoiceUpdate.printedNo" placeholder="请输入打印号码" maxlength="8" />
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="开票信息" name="kaipiao" class="invoice-update">
            <el-form label-position="top" :model="invoiceUpdate" :rules="rules">
              <h5 class="kp_title">购方信息</h5>
              <el-row type="flex" justify="space-between">
                <el-col :span="6">
                  <el-form-item label="名称" prop="buyerName">
                    <el-input v-model="invoiceUpdate.buyerName" placeholder="" maxlength="12" />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="纳税人识别号" prop="buyerTaxNo">
                    <el-input v-model="invoiceUpdate.buyerTaxNo" placeholder="" maxlength="12" />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="地址及电话" prop="buyerAddressTel">
                    <el-tooltip class="item" effect="dark" :content="invoiceUpdate.buyerAddressTel" placement="top-start"> <el-input v-model="invoiceUpdate.buyerAddressTel" placeholder="" maxlength="12" /></el-tooltip>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="开户行及账号" prop="buyerBankAccount">
                    <el-tooltip class="item" effect="dark" :content="invoiceUpdate.buyerBankAccount" placement="top-start"><el-input v-model="invoiceUpdate.buyerBankAccount" placeholder="" maxlength="12" /></el-tooltip>
                  </el-form-item>
                </el-col>
              </el-row>
              <h5 class="kp_title" style="margin-top: 40px">销方信息</h5>
              <el-row type="flex" justify="space-between">
                <el-col :span="6">
                  <el-form-item label="名称" prop="sellerName">
                    <el-input v-model="invoiceUpdate.sellerName" placeholder="" maxlength="12" />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="纳税人识别号" prop="sellerTaxNo">
                    <el-input v-model="invoiceUpdate.sellerTaxNo" placeholder="" maxlength="12" />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="地址及电话" prop="sellerAddressTel">
                    <el-tooltip class="item" effect="dark" :content="invoiceUpdate.sellerAddressTel" placement="top-start"> <el-input v-model="invoiceUpdate.sellerAddressTel" placeholder="" maxlength="12" /></el-tooltip>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="开户行及账号" prop="sellerBankAccount">
                    <el-tooltip class="item" effect="dark" :content="invoiceUpdate.sellerBankAccount" placement="top-start"> <el-input v-model="invoiceUpdate.sellerBankAccount" placeholder="" maxlength="12" /></el-tooltip>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="开票明细" name="kpmx" class="invoice-update">
            <InvoiceOpenGoodsList class="incvoice_open_goods_list" ref="invoiceGoodsList" :goods-list="goodsList" biz-type="manual" @handleFocusTd="handleFocusTd" @handleInsertMerchandise="handleInsertMerchandise" @handleDiscount="handleDiscount" @changeIndex="changeIndex" @dataSelect="handleCurrentGoods" @handleShowGoods="handleShowGoods" @nameBlur="handleMerchandiseNameBlur" @reviewInvoice="handleReviewInvoice" @handleDeleteItems="handleDeleteItems" />
          </el-tab-pane>
        </el-tabs>
        <div class="show_photo_btn" style="position: absolute; top: 8px; right: 0">显示影像&nbsp;&nbsp;<el-switch v-model="isShowPhoto"> </el-switch></div>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="handleInvoiceUpdateCancel('invoiceUpdate')">取消</el-button>
        <el-button type="primary" @click="handleInvoiceUpdateConfirm('invoiceUpdate')">提交</el-button>
      </div>
    </el-dialog>

    <!-- 选择商品 -->
    <el-dialog append-to-body title="选择商品" :visible.sync="dialogGoodsListVisible" width="804px" :show-close="false">
      <merchandise-list-dialog v-if="dialogGoodsListVisible" @handleCurrentGoods="handleCurrentGoods"></merchandise-list-dialog>
    </el-dialog>

    <!-- 票据影像弹窗 -->
    <el-image-viewer v-if="invoiceImageViewer" :url-list="invoiceImages" :on-close="handleImageViewerClose" />

    <!-- 删除弹窗 -->
    <el-dialog append-to-body title="提示" width="400px" :visible.sync="invoiceDeleteVisible" @close="handleInvoiceDeleteCancel">
      <div class="dialog-body">是否删除该张发票？</div>
      <div class="dialog-footer" slot="footer">
        <el-button @click="handleInvoiceDeleteCancel">取 消</el-button>
        <el-button type="primary" @click="handleInvoiceDeleteConfirm">确 定</el-button>
      </div>
    </el-dialog>

    <other-invoice-detail :invoiceUpdate="invoiceUpdate" :show.sync="updateVisible" @cancel="handleOtherImageDetailCancel" @save="handleOtherImageDetailSave"></other-invoice-detail>

    <!-- 移动发票 -->
    <el-dialog title="移动发票至" width="700px" :visible.sync="invoiceMoveVisible" @close="invoiceMoveVisible = false">
      <div class="dialog-body">
        <el-form inline>
          <el-form-item label="发票代码" :rules="{ required: true, message: '请选择移至位置发票代码', trigger: 'blur' }">
            <el-select multiple v-model="search.statusList" collapse-tags placeholder="请选择移至位置发票代码">
              <el-option v-for="item in recognizeStatus" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="发票号码" :rules="{ required: true, message: '请选择移至位置发票号码', trigger: 'blur' }" style="margin-left: 10px">
            <el-select multiple v-model="search.statusList" collapse-tags placeholder="请选择移至位置发票号码">
              <el-option v-for="item in recognizeStatus" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="dialog-footer" slot="footer">
        <el-button @click="invoiceMoveVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleInviceMoveDialog">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 提交 -->
    <div class="submit_Allow_style">
      <el-dialog title="发票清单不完整，是否继续提交？" :visible.sync="dialogSubmitAllow" center width="25%">
        <span style="padding: 36px 20px">系统已标记出发票已有的清单</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogSubmitAllow = false">取 消</el-button>
          <el-button type="primary" @click="handlerSubmitNo">提 交</el-button>
        </span>
      </el-dialog>
    </div>

    <div class="submit_Allow_style">
      <el-dialog title="发票清单不完整，请补全后提交" :visible.sync="dialogSubmitNotAllow" center width="25%">
        <span>系统已标记出发票已有的清单</span>
        <span slot="footer" class="dialog-footer" style="text-align: center">
          <el-button type="primary" @click="dialogSubmitNotAllow = false">确 定</el-button>
        </span>
      </el-dialog>
    </div>

    <el-dialog title="添加发票" :visible.sync="continueToAdd" width="990px" :before-close="handleClose">
      <div class="exist-data">
        <div class="show-container" v-for="recognize in recognizes" :key="recognize.url">
          <img src="@/assets/img/invoiceCenter/Close@2x.png" @click="handleDeleteShowInvoice(recognize.url)" />
          <div v-if="recognize.ifType == 'ifOfd'" class="show-invoice">
            <div class="xml_con_box">
              <div>ofd文件</div>
            </div>
          </div>
          <div v-else-if="recognize.ifType == 'ifXml'" class="show-invoice">
            <div class="xml_con_box">
              <div>xml文件</div>
            </div>
          </div>
          <div v-else class="show-invoice">
            <el-image :src="recognize.url" :preview-src-list="[recognize.url]"></el-image>
          </div>
        </div>
        <el-upload action="#" accept=".jpg,.jpeg,.png,.tif,.bmp,.JPG,.JPEG,.PNG,.TIF,.BMP,.xml,.pdf,.ofd" multiple :show-file-list="false" :auto-upload="false" :on-change="handlerSelectFile">
          <div class="add___">
            <i class="el-icon-plus avatar-uploader-icon"></i>
          </div>
        </el-upload>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handlerRecognition">识 别</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { DataUtil } from '@/util/dataUtil';
import { getTntConfig } from '@/service/system/config';
import { changePdfToImage, getRecognizeFailureRecord, getInvoiceRecognizeImage, updateImageInvoice, againRecognize, deleteImageInvoice, unqueriedImage } from '@/service/invoice-center';
import '@/style/select.scss';
import RegexLib from '@/assets/js/regex-lib';
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
import { RECOGNIZE_INVOICE_TYPES, RECOGNIZE_INVOICE_STATUS, RECOGNIZE_FAILURE_CHANNEL, RECOGNIZE_INVOICE_SEAL, RECOGNIZE_FAILURE_RECORD_LINE } from '@/content/invoice';
import { tableStyle, pagingMethods, dateOption, changeMethods, commonsMethods } from '@/util/mixins';
import OtherInvoiceDetail from '@/components/invoice/OtherInvoiceDetail';
import InvoiceOpenGoodsList from '@/views/invoice-open/invoice-issue/retail/components/RetailInvoiceOpenGoodsList.vue';
import MerchandiseListDialog from '@/views/invoice-open/invoice-issue/components/MerchandiseListDialog.vue';
import cusMessage from '@/components/message/Message';
import DragScaleImg from '@/components/DragScaleImg';
export default {
  name: 'RecognizeFailureRecord',
  mixins: [tableStyle, pagingMethods, dateOption, changeMethods, commonsMethods],
  components: { ElImageViewer, OtherInvoiceDetail, InvoiceOpenGoodsList, MerchandiseListDialog, cusMessage, DragScaleImg },
  props: {
    btnStatus: {
      type: Boolean,
      default: true
    },
    isComplete: {
      type: Array,
      default: () => {
        return [];
      },
    },
    curBatchNo: {
      type: String,
      default: ''
    },
    from: {
      type: String,
      default: ''
    },
    isShowSearch: {
      type: Boolean,
      default: true
    },
    unqueriedImageList: {
      type: Array,
      default: () => {}
    }
  },
  watch: {
    invoiceUpdateVisible(val) {
      if (!val) {
        this.imgPre = '';
      }
    },
    isComplete: {
      handler(val) {
        // console.log(val);
        // this.tableData = val
        this._unqueriedImage();
      }
    },
    btnStatus: {
      handler(val) {
        // console.log(val);
        if (!val) {
          // let map = new Map();
          // this.unqueriedImageList.forEach((item) => {
          //   for (let i of item.imageInvoiceLists) {
          //     if (!map.has(i.pageIndex)) {
          //       map.set(i.pageIndex, i);
          //     }
          //     item.imageInvoiceLists = [...map.values()];
          //   }
          // });

          // this.tableData = this.unqueriedImageList;
          this._getUnqueriedImage();

          // this.getDataListFromLocalCollectInvoicePage();
        }
      },
      deep: true
    }
  },
  data() {
    return {
      hasData: false,
      tips: '本次识别的清单数 / 已提交清单数 / 清单总数',
      invoiceMoveVisible: false,
      imgPre: '',
      isShowPhoto: false,
      activeName: 'master',
      dialogGoodsListVisible: false,
      currentGoodsIndex: 0,
      goodsList: [],
      isExistListArr: [
        { value: true, label: '是' },
        { value: false, label: '否' }
      ],
      isInvoiceSealArr: [
        { value: 1, label: '已盖章' },
        { value: 0, label: '未盖章' }
      ],

      invoiceStatusList: [
        { label: '蓝票', value: '0' },
        { label: '红票', value: '1' },
        { label: '全额冲红', value: '2' },
        { label: '作废', value: '3' },
        { label: '部分冲红', value: '4' }
      ],
      updateVisible: false,
      columns: {
        invoiceCode: {
          label: '发票代码',
          visible: true
        },
        invoiceNo: {
          label: '发票号码',
          visible: true
        },
        isExistList: {
          label: '是否存在清单',
          visible: true
        },
        rider: {
          label: '乘车人',
          visible: true
        },
        train21No: {
          label: '电子客票号码/21位码',
          visible: true
        },
        batchNo: {
          label: '识别批次',
          visible: true
        },
        batchIndex: {
          label: '识别批次位置',
          visible: true
        },
        status: {
          label: '识别状态',
          visible: true
        },
        receiveTime: {
          label: '收票时间',
          visible: true
        },
        invoiceTime: {
          label: '开票日期',
          visible: true
        },
        invoiceType: {
          label: '发票类型',
          visible: true
        },
        channel: {
          label: '收票方式',
          visible: true
        },
        amount: {
          label: '金额(不含税)',
          visible: true
        },
        taxAmount: {
          label: '税额',
          visible: true
        },
        sumAmount: {
          label: '价税合计',
          visible: true
        },

        checkCode: {
          label: '校验码后六位',
          visible: true
        },
        pageSize: {
          label: '总页数',
          visible: true
        },
        pageIndex: {
          label: '当前页数',
          visible: true
        },
        creatorName: {
          label: '上传者',
          visible: true
        },
        orgName: {
          label: '上传组织',
          visible: true
        },
        sender: {
          label: '发件人',
          visible: true
        },
        senderEmail: {
          label: '发件邮箱',
          visible: true
        },
        eInvoiceNo: {
          label: '数电发票号码',
          visible: true
        },
        remark: {
          label: '备注',
          visible: true
        },
        operate: {
          label: '操作',
          visible: true
        }
      },
      loading: null,
      recognize: {
        ids: this.afreshMatchIds
      },
      afreshMatchIds: [],
      selections: [],
      colData: RECOGNIZE_FAILURE_RECORD_LINE,
      isIndeterminate: true,
      checkAll: false,
      /* 识别表格数据区 */
      tableData: [],
      tableLoading: false,
      search: {
        eInvoiceNo: '',
        remark: '',
        sender: '',
        invoiceCode: '',
        invoiceNo: '',
        batchNo: '',
        channel: '',
        status: '',
        page: 1,
        size: 15,
        train21No: '',
        rider: '',
        statusList: [RECOGNIZE_INVOICE_STATUS[0].value, RECOGNIZE_INVOICE_STATUS[1].value]
      },
      total: 0,
      /* 发票编辑数据区 */
      invoiceUpdateVisible: false,
      invoiceUpdate: {
        id: '',
        invoiceType: '',
        invoiceCode: '',
        invoiceNo: '',
        checkCode: '',
        invoiceTime: '',
        channel: '',
        pageSize: '',
        pageIndex: '',
        isExistList: '',
        invoiceLabel: '',
        payee: '',
        auditor: '',
        payer: '',
        invoiceSeal: '',
        printedBrushCode: '',
        printedCode: '',
        printedBrushNo: '',
        printedNo: '',
        rider: '',
        train21No: '',
        settlementId: '',
        invoiceStatus: '',
        eInvoiceNo: ''
      },
      rules: {
        invoiceCode: [
          { required: true, message: '请输入发票代码', trigger: 'blur' },
          { min: 10, max: 12, message: '请输入10位或12位的发票代码', trigger: 'blur' },
          { pattern: RegexLib.InvoiceCode, message: '请输入正确的发票代码' }
        ],
        invoiceNo: [
          { required: true, message: '请输入发票号码', trigger: 'blur' },
          { min: 8, max: 8, message: '请输入8位发票号码', trigger: 'blur' },
          { pattern: RegexLib.InvoiceNo, message: '请输入正确的发票号码' }
        ],
        amount: [
          { required: true, message: '请输入发票金额', trigger: 'blur' },
          { pattern: RegexLib.Amount, message: '请输入正确的金额' }
        ],
        checkCode: [
          { required: true, message: '请输入校验码', trigger: 'blur' },
          { min: 6, max: 6, message: '请输入校验码后六位', trigger: 'blur' },
          { pattern: RegexLib.InvoiceVerifyCode, message: '请输入正确的校验码' }
        ],
        pageSize: [
          { required: true, message: '请输入总页数', trigger: 'blur' },
          { min: 1, message: '请输入至少1位总页数', trigger: 'blur' },
          { pattern: RegexLib.PageSize, message: '请输入正确的总页数' }
        ],
        pageIndex: [
          { required: true, message: '请输入当前页数', trigger: 'blur' },
          { min: 1, message: '请输入至少1位当前页数', trigger: 'blur' },
          { pattern: RegexLib.PageIndex, message: '请输入正确的当前页数' }
        ],
        isExistList: [{ required: true, message: '请选择', trigger: 'blur' }]
      },
      invoiceTypes: RECOGNIZE_INVOICE_TYPES,
      recognizeStatus: RECOGNIZE_INVOICE_STATUS,
      invoiceChannels: RECOGNIZE_FAILURE_CHANNEL,
      invoiceSeals: RECOGNIZE_INVOICE_SEAL,
      /* 票据影像数据区 */
      invoiceImages: [],
      invoiceImageViewer: false,
      /* 票据删除数据区 */
      invoiceDeleteVisible: false,
      imageInvoiceId: '',
      /* 数据字典数据区 */
      invoiceChannel: [],
      selectExpended: false,
      pickerDisabled: {
        disabledDate(date) {
          return date.getTime() > Date.now();
        }
      },
      curEditInvoiceIds: [], //列表里编辑过的ids
      allowSybmit: '',
      dialogSubmitAllow: false,
      dialogSubmitNotAllow: false,
      continueToAdd: false,
      recognizes: [],
      isFileUploading: false
    };
  },
  computed: {
    hasCheckCode() {
      let invoiceType = this.invoiceUpdate.invoiceType;
      return invoiceType == 'SpecInvoice' || invoiceType == 'VehInvoice' ? false : true;
    },
    isInvoiceList() {
      let invoiceType = this.invoiceUpdate.invoiceType;
      return invoiceType === 'VatInvoiceList';
    }
  },
  created() {
    this.handleGetVerifySwitch('INV_RECEIVE_INCOMPLETE_ALLOWED_TO_SUBMIT');
    if (this.from === 'local_collect_invoice' || this.from === 'scanner_collect_invoice') return;
    this.search.batchNo = this.$route.query.batchNo;
    this.search.status = this.$route.query.status;
    this.handleInitDictionaries();
    this.handleGetRecognizeFailureRecordData();
    this.handleGetlineSet();
  },
  methods: {
    handleClose() {
      this.recognizes = [];
      this.continueToAdd = false;
    },
    handlerRecognition() {
      this.$emit('handlerBtnStatus', true, this.recognizes);
      this.recognizes = [];
      this.continueToAdd = false;
    },
    /* 删除展示图片 */
    handleDeleteShowInvoice(url) {
      this.recognizes.some((item, i) => {
        if (item.url == url) {
          this.recognizes.splice(i, 1);
          return true;
        }
      });
    },
    /* PDF文件上传 */
    async handleUploadPdfFile(value) {
      const loading = this.handleLoading('PDF解析中，请稍后...');
      // PDF解析请求
      let params = new FormData();
      value.file = value.raw;
      params.append('file', value.file);
      const { success, data } = await changePdfToImage(params);
      if (!success) {
        this.toast('PDF解析异常！', 'error');
        loading.close();
        return;
      }
      // 解析结果处理
      data.forEach((item) => {
        // Base64图片转文件
        let arr = item.split(',');
        let mime = arr[0].match(/:(.*?);/)[1];
        let bytes = atob(arr[1]);
        let n = bytes.length;
        let ia = new Uint8Array(n);
        while (n--) {
          ia[n] = bytes.charCodeAt(n);
        }
        let file = new File([ia], value.file.name, { type: mime });
        // 生成地址
        let url = URL.createObjectURL(file);
        let compressFile = null;
        if (file.size > 1024 * 1024 * 5) {
          imageConversion.compressAccurately(file, 2048).then((res) => {
            compressFile = new File([res], file.name, { type: file.type });
            // console.log(compressFile, '-----compressFile------');
            this.recognizes.push({ url: URL.createObjectURL(compressFile), image: compressFile, original: compressFile, ifType: 'isPdf' });
          });
        } else {
          this.recognizes.push({ url: url, image: value.file, original: value.file, ifType: 'isPdf' });
        }
      });
      if (this.channel == null || this.channel == '' || this.channel == 'PICTURE') {
        this.channel = 'PDF';
      }
      loading.close();
    },
    handlerSelectFile(file) {
      this.hasData = true;
      // this.$parent.handlerSelectFile(file)
      console.log(file, 'file, fileList');
      let xmlTesx = /xml$/;
      let pdfTesx = /pdf$/;
      let ofdTesx = /ofd$/;
      this.batchDiscern = true;
      if (xmlTesx.test(file.name)) {
        let url = URL.createObjectURL(file.raw);
        this.recognizes.push({ url: url, image: file.raw, original: file.raw, ifType: 'ifXml' });
      } else if (pdfTesx.test(file.name)) {
        this.isFileUploading = true;
        console.log(file, '---file--ww');
        this.handleUploadPdfFile(file);
      } else if (ofdTesx.test(file.name)) {
        let url = URL.createObjectURL(file.raw);
        this.recognizes.push({ url: url, image: file.raw, original: file.raw, ifType: 'ifOfd' });
      } else {
        let url = URL.createObjectURL(file.raw);
        let compressFile = null;
        // 图片大于5M就压缩
        if (file.size > 1024 * 1024 * 5) {
          imageConversion.compressAccurately(file.raw, 2048).then((res) => {
            compressFile = new File([res], file.name, { type: file.type });
            this.recognizes.push({ url: URL.createObjectURL(compressFile), image: compressFile, original: compressFile, ifType: 'ifImg' });
          });
        } else {
          this.recognizes.push({ url: url, image: file.raw, original: file.raw, ifType: 'ifImg' });
        }
      }
    },
    async handleGetVerifySwitch(type) {
      await getTntConfig(type).then(({ success, data }) => {
        if (type == 'INV_RECEIVE_INCOMPLETE_ALLOWED_TO_SUBMIT') {
          console.log(data, 'data成功');
          this.allowSybmit = data == 'Y' ? true : false;
        }
      });
    },
    handlerSubmitNo() {
      this.$parent.handlerSubmit();
      this.dialogSubmitAllow = false;
    },
    // 继续添加
    handlerContinueToAdd() {
      this.continueToAdd = true;
    },
    handlerSubmit() {
      // 判断是否完整
      const isComplete = this.isComplete.every((item) => {
        return item == true;
      });
      // console.log(isComplete, 'isComplete');
      if (isComplete) {
        this.$parent.handlerSubmit();
      } else {
        // 不完整允许
        if (this.allowSybmit) {
          this.dialogSubmitAllow = true;
        } else {
          this.dialogSubmitNotAllow = true;
        }
      }
    },
    _getUnqueriedImage() {
      this._unqueriedImage();
      // 没有清单，不显示子类
      this.$nextTick(() => {
        let noChildren = document.getElementsByClassName('no_children');
        for (let i = 0; i < noChildren.length; i++) {
          const cn = noChildren[i].children[2];
          for (let j = 0; j < cn.children.length; j++) {
            cn.children[j].style.display = 'none';
          }
        }
        let hasChildren = document.getElementsByClassName('has_children');
        for (let i = 0; i < hasChildren.length; i++) {
          const cn = hasChildren[i].children[2];
          for (let j = 0; j < cn.children.length; j++) {
            cn.children[j].style.display = 'block';
          }
        }
      });
    },
    _unqueriedImage() {
      unqueriedImage({ batchNo: this.curBatchNo, unIds: null }).then((res) => {
        const { success, data } = res;
        if (success) {
          // let map = new Map();
          // for (let i of data) {
          //   if (!map.has(i.invoiceCode) && !map.has(i.invoiceNo)) {
          //     map.set(i.invoiceCode, i);
          //   }
          // }
          // this.tableData = [...map.values()];
          this.tableData = data.sort((a, b) => {
            return a.isComplete - b.isComplete;
          });
          console.log(this.tableData, 'this.tableData');
          // this.unqueriedImageList = [...map.values()];
        }
      });
    },

    handleInviceMoveDialog() {},
    handleInvoiceMove(row) {
      this.invoiceMoveVisible = true;
    },
    tableRowClassName({ row, rowIndex }) {
      return row.isExistList ? 'has_children' : 'no_children';
    },
    // 表格项被焦点
    handleFocusTd(val) {
      this.currentGoodsIndex = val.index;
      // 商品明细已经填写了 "名称 & 金额 & 税率"
      if (val.merchandiseName && val.amount && val.taxRate != null) {
        this.hadTdFocus = val.focus;
        this.discountStatus = val.itemProperty > 0 ? true : false;
      } else {
        this.hadTdFocus = false;
      }
    },
    // 新增商品信息弹窗
    handleInsertMerchandise(row) {
      this.merchandise.index = row.index;
      this.merchandise.merchandiseName = row.merchandiseName;
      this.editVisible = !!this.merchandise.merchandiseName;
    },
    // 显示折扣相关弹窗
    handleDiscount() {
      let goods = this.goodsList.find((i) => i.index == this.currentGoodsIndex);
      this.discountForm = { ...this.discountForm, ...goods };
      this.deleteGoods = goods.itemProperty == '2';
      this.discountStatus ? (this.deletedDiscountVisible = true) : (this.insertDiscountVisible = true);
    },
    // 商品标识符
    changeIndex(value) {
      this.goodsIndex += value;
    },
    // 商品选中
    handleCurrentGoods(row) {
      let goods = this.goodsList.find((i) => i.index == this.currentGoodsIndex);
      if (!goods) return;

      if (row.specification) {
        goods.specification = row.specification;
      }
      if (row.unit) {
        goods.unit = row.unit;
      }
      // 免税-不征税明细
      if (row.taxPreferentialContent == '免税' || row.taxPreferentialContent == '不征税') {
        goods['taxRateModel'] = row.taxPreferentialContent;
        goods['taxRate'] = 0;
      } else {
        goods['taxRate'] = Number(row.taxRate);
        goods['taxRateModel'] = Number(row.taxRate);
      }
      goods.price = row.price == 0 ? null : row.price;
      goods.uniqueCode = row.uniqueCode;
      goods.taxClassCode = row.taxClassCode;

      // 显示值
      if (!row.merchandiseName && row.untaxMerchandiseName) {
        row.merchandiseName = row.untaxMerchandiseName;
      }
      goods.merchandiseName = /^\*/.test(row.merchandiseName) ? row.merchandiseName : `*${row.taxClassName}*${row.merchandiseName}`;
      goods.notFirst = true;
      goods.taxClassName = row.taxClassName;
      goods.taxVersionNo = row.taxVersionNo;
      goods.isTaxPreferential = row.isTaxPreferential;
      goods.taxPreferentialContent = row.taxPreferentialContent;
      goods.priceVariable = row.priceVariable;
      goods.visible = false;

      this.$refs.invoiceGoodsList.handleKeyupGoodsPrice(goods);
      this.dialogGoodsListVisible = false;
      let inputDom = document.getElementById('input-' + row.index);
      inputDom ? inputDom.blur() : null;

      // 焦点移动
      setTimeout(() => this.$refs.invoiceGoodsList.focusSpec(goods.index), 0);
    },
    // 展开商品选择弹窗
    handleShowGoods(goods) {
      this.dialogGoodsListVisible = true;
      this.currentGoodsIndex = goods.index;
    },
    // 输入货物清单通知
    handleMerchandiseNameBlur(row, merchandises) {
      //  失去焦点行下标
      this.currentGoodsIndex = row.index;
      if (!row.taxClassCode) {
        this.handleInsertMerchandise(DataUtil.clone(row));
        return;
      }
      this.handleCurrentGoods(row);
    },
    // 发票价税补全
    handleReviewInvoice(invoicePrice) {
      // this.invoicePrice = invoicePrice;
    },
    handleDeleteItems(index) {
      // this.deleteIndex = index;
    },
    getDataListFromLocalCollectInvoicePage() {
      this.search.batchNo = this.curBatchNo;
      this.search.status = '';
      this.search.statusList = [];
      this.handleGetRecognizeFailureRecordData();
    },
    handleOtherImageDetailSave() {
      this.invoiceUpdate = {};
      this.handleGetRecognizeFailureRecordData();
      this.toast('修改成功', 'success');
    },
    handleOtherImageDetailCancel() {
      this.updateVisible = false;
    },
    //重新识别
    afreshMatch() {
      // 选中数量检查
      if (this.selections.length === 0) {
        this.toast('请勾选需要操作的内容！', 'warning');
        return;
      }
      this.loading = this.handleLoading('发票识别中，请稍后...');
      let param = {
        ids: this.selections.map((item) => item.id)
      };
      againRecognize(param)
        .then((res) => {
          if (res.success) {
            this.invoiceUpdateVisible = false;
            // 重新识别在不同页面的调用的列表接口
            if (this.from === 'local_collect_invoice' || this.from === 'scanner_collect_invoice') {
              this._getUnqueriedImage();
            } else {
              this.handleGetRecognizeFailureRecordData();
            }
            this.loading.close();
            this.toast('执行重新识别成功', 'success');
          } else {
            this.toast('执行重新识别失败', 'error');
            this.loading.close();
          }
        })
        .catch((err) => {
          this.toast('执行重新识别出错', 'error');
          this.invoiceUpdateVisible = false;
          this.handleGetRecognizeFailureRecordData();
          this.loading.close();
        });
    },
    // 数据展开
    handleExpandChange(row) {
      console.log(row);
    },
    //数据区选中
    handleSelection(value) {
      this.selections = value;
    },
    //获取列设置
    handleGetlineSet() {
      if (localStorage.getItem('recognizeFailureLineSet') != null) {
        this.columns = Object.assign(this.columns, JSON.parse(localStorage.getItem('recognizeFailureLineSet')));
      }
    },
    //列设置全选
    handleCheckAllChange(val) {
      this.isIndeterminate = false;
      let cols = this.columns;
      for (let p in cols) {
        cols[p].visible = val;
      }
      localStorage.setItem('recognizeFailureLineSet', JSON.stringify(this.columns));
    },
    //列设置改变
    handleCheckedCitiesChange(pk, pv) {
      Object.keys(this.columns).some((key) => {
        if (pk == key) {
          this.columns[key].visible = pv.visible;
          return true;
        }
      });
      localStorage.setItem('recognizeFailureLineSet', JSON.stringify(this.columns));
    },
    /* 识别失败记录获取 */
    async handleGetRecognizeFailureRecordData() {
      this.tableLoading = true;
      // 识别失败记录获取请求
      const { success, data } = await getRecognizeFailureRecord(this.search);
      if (!success) {
        this.toast('识别失败记录获取失败！', 'error');
        this.tableLoading = false;
        return;
      }
      // let records = JSON.parse(JSON.stringify(data.records)) || [];
      // let tmpChild = [];
      // let tmpIdx = [];

      // records.map((item, index) => {
      //   item.children = [];
      //   item.hasChildren = false;

      //   // 发票清单
      //   if (item.invoiceType == 'VatInvoiceList') {
      //     item.hasChildren = true;
      //     tmpChild = [item, ...tmpChild];
      //     records[index].children = JSON.parse(
      //       JSON.stringify(
      //         tmpChild.filter((v) => {
      //           return v.batchNo == item.batchNo;
      //         })
      //       )
      //     );
      //     if (records[index + 1] && records[index + 1].batchNo && records[index].batchNo == records[index + 1].batchNo) {
      //       tmpIdx = [index, ...tmpIdx];
      //     }
      //   }
      // });

      // tmpIdx.forEach((item) => {
      //   delete records[item];
      // });

      // const tmpRecords = records.filter((d) => d);

      // for (let i = 0; i < tmpRecords.length - 1; i++) {
      //   let item = tmpRecords[i];
      //   let nItem = tmpRecords[i + 1];
      //   if (item.invoiceCode == nItem.invoiceCode && item.invoiceNo == nItem.invoiceNo && nItem.invoiceType == 'VatInvoiceList') {
      //     item.children = nItem.children;
      //     item.hasChildren = true;
      //   }
      // }
      // const arr = tmpRecords.filter((item) => item.invoiceType != 'VatInvoiceList');

      // 查询结果处理
      this.total = data.total;
      this.tableData = data.records;
      console.log(this.tableData, '---tableData---');
      // this.tableData = records;

      this.tableLoading = false;
    },
    /* 初始化字典值 */
    handleInitDictionaries() {
      this.getDicts('invoice_channel').then(
        ({ data }) =>
          (this.invoiceChannel = [
            {
              label: '全部',
              value: ''
            },
            ...data
          ])
      );
    },
    /* 展开筛选列表 */
    handleListShow() {
      this.selectExpended = true;
    },
    /* 收起筛选列表 */
    handleListClose() {
      this.selectExpended = false;
    },
    /* 重置查询 */
    handleReset() {
      (this.search = {
        remark: '',
        sender: '',
        invoiceCode: '',
        invoiceNo: '',
        batchNo: '',
        channel: '',
        status: '',
        page: 1,
        size: 15,
        train21No: '',
        rider: ''
      }),
        this.handleGetRecognizeFailureRecordData();
    },
    /* 发票信息编辑弹窗 */
    handleInvoiceUpdatePopup(invoice) {
      console.log(invoice, '----识别记录');
      if (invoice.invoiceType == 'TrainInvoice' || invoice.invoiceType == 'TaxiInvoice' || invoice.invoiceType == 'TollInvoice' || invoice.invoiceType == 'PlaneInvoice' || invoice.invoiceType == 'GeneralMachineInvoice' || invoice.invoiceType == 'TollInvoice' || invoice.invoiceType == 'PassengerInvoice' || invoice.invoiceType == 'QuotaInvoice') {
        this.updateVisible = true;
      } else {
        this.invoiceUpdateVisible = true;
      }
      if (invoice.invoiceJson) {
        const tmpInvoiceJson = JSON.parse(invoice.invoiceJson);
        console.log(tmpInvoiceJson);
        if (tmpInvoiceJson.details && tmpInvoiceJson.details.length) {
          this.goodsList = tmpInvoiceJson.details.map((item) => {
            return {
              ...item,
              merchandiseName: item.goodName,
              specification: item.general,
              unit: item.specifications,
              quantity: item.goodNum,
              price: item.goodPrice,
              sumAmount: item.amount,
              taxRateModel: item.taxRate
              // taxRate: item.taxRate,
              // taxRateString:item.taxRateString,
              // taxAmount: item.taxAmount
            };
          });
        }
      }
      this.invoiceUpdate = {
        ...invoice,
        id: invoice.id,
        batchNo: invoice.batchNo,
        invoiceType: invoice.invoiceType,
        invoiceCode: invoice.invoiceCode,
        invoiceNo: invoice.invoiceNo,
        amount: invoice.amount,
        pageSize: invoice.pageSize,
        pageIndex: invoice.pageIndex,
        checkCode: invoice.checkCode.substring(invoice.checkCode.length - 6),
        invoiceTime: invoice.invoiceTime,
        source: 'CENTER',
        invoiceLabel: 'ordinary',
        channel: invoice.channel,
        isExistList: invoice.isExistList,
        invoiceSeal: invoice.invoiceSeal,
        payee: invoice.payee,
        auditor: invoice.auditor,
        payer: invoice.payer,
        // printedBrushCode: '',
        // printedCode: '',
        // printedBrushNo: '',
        // printedNo: '',
        rider: invoice.rider,
        train21No: invoice.train21No,
        settlementId: invoice.settlementId
      };
      this.handleInvoiceRecognizeImage(invoice, false);
    },
    /* 发票信息编辑确定 */
    handleInvoiceUpdateConfirm(invoiceUpdate) {
      this.goodsList = this.goodsList.map((item) => {
        return {
          ...item,
          goodName: item.merchandiseName,
          general: item.specification,
          specifications: item.unit,
          goodNum: item.quantity,
          goodPrice: item.price,
          amount: item.sumAmount
          // taxRate: item.taxRate,
          // taxRateModel:item.taxRateModel,
          // taxRateString:item.taxRateString,
          // taxAmount: item.taxAmount
        };
      });

      const tmpParams = {
        ...this.invoiceUpdate,
        invoiceJson: { details: this.goodsList }
      };

      this.$refs[invoiceUpdate].validate((valid) => {
        if (valid) {
          updateImageInvoice(tmpParams).then((res) => {
            if (res.success) {
              this.invoiceUpdateVisible = false;
              this.toast('操作成功', 'success');
              console.log(tmpParams, 'curEditInvoiceIds-11111----tmpParams----');
              if (this.from === 'local_collect_invoice' || this.from === 'scanner_collect_invoice') {
                this.curEditInvoiceIds = [tmpParams.id, ...this.curEditInvoiceIds];

                this.$emit('getCurEditInvoiceIds', this.curEditInvoiceIds);
                this._unqueriedImage();
                return;
              }
              this.handleGetRecognizeFailureRecordData();
            }
          });
        } else {
          return false;
        }
      });
    },
    /* 发票信息编辑取消 */
    handleInvoiceUpdateCancel(invoiceUpdate) {
      this.$refs[invoiceUpdate].resetFields();
      this.invoiceUpdateVisible = false;
      this.activeName = 'master';
    },
    /* 发票影像 */
    async handleInvoiceRecognizeImage(invoice, isLook = true) {
      // 识别影像请求
      if (!invoice.invoiceFileId) return;
      const { success, data } = await getInvoiceRecognizeImage(invoice.invoiceFileId);
      if (!success) {
        this.toast('识别影像获取失败！', 'error');
        return;
      }
      // 识别影像结果处理
      if (isLook) {
        this.invoiceImageViewer = true;
      }
      this.imgPre = data;
      this.invoiceImages.push(data);
    },
    /* 发票影像弹窗关闭 */
    handleImageViewerClose() {
      this.invoiceImages = [];
      this.invoiceImageViewer = false;
    },
    /* 删除发票 */
    handleInvoiceDeletePopup(invoice) {
      this.imageInvoiceId = invoice.id;
      this.idDelete = invoice.id;

      this.invoiceDeleteVisible = true;
    },
    /* 确认删除发票信息 */
    async handleInvoiceDeleteConfirm() {
      this.invoiceDeleteVisible = false;
      const { success } = await deleteImageInvoice(this.imageInvoiceId);
      if (success) {
        this.$emit('_handlerDelete', this.idDelete);
        this.toast('删除成功！', 'success');
        if (this.from === 'local_collect_invoice' || this.from === 'scanner_collect_invoice') {
          this._unqueriedImage();
          return;
        }
        await this.handleGetRecognizeFailureRecordData();
      } else {
        this.toast('删除失败！', 'error');
      }
    },
    /* 取消删除发票信息 */
    handleInvoiceDeleteCancel() {
      this.imageInvoiceId = '';
      this.invoiceDeleteVisible = false;
    },
    /* 切换分页条数 */
    handleSizeChange(value) {
      this.search.page = value;
      this.handleGetRecognizeFailureRecordData();
    },
    // 翻页
    handleCurrentChange(value) {
      this.search.page = value;
      this.handleGetRecognizeFailureRecordData();
    },
    /* 识别发票失败记录表格格式化 */
    handleTableValueFormat(row, column) {
      let value = row[column.property];
      switch (column.label) {
        case '组织名称':
          if (value === '未匹配') return '--';
          return value;
        case '发票类型':
          return this.handleValueToLabel('InvoiceType', value);
        case '金额(不含税)':
          if (value == '') return '--';
          return '￥' + value;
        case '收票方式':
          if (value != null && value != '') {
            return this.getDictLabel(this.invoiceChannel, value);
          }
          return '--';
        case '发件人':
        case '备注':
        case '发件邮箱':
        case '识别批次':
        case '乘车人':
        case '电子客票号码/21位码':
        case '上传组织':
        case '数电发票号码':
        case '上传者':
          if (value) return value;
          return '--';
        case '校验码后六位':
          if (value == '' || value == null) return '--';
          return value.substring(value.length - 6, value.length);
        case '开票日期':
          if (value != undefined) {
            return this.$moment(value).format('YYYY-MM-DD');
          }
          return '--';
        case '收票日期':
          if (value != undefined) {
            return this.$moment(value).format('YYYY-MM-DD HH:mm:ss');
          }
          return '--';
        case '识别批次位置':
          if (value != null && value != '') {
            return '第' + value + '张';
          }
          return '--';
        case '是否存在清单':
          if (value) {
            return '是';
          }
          return '否';
        case '总页数':
          if (value) return value;
          return '--';
        case '当前页数':
        case '税额':
        case '价税合计':
          if (value) return value;
          return '--';
        default:
          return value;
      }
    },
    /* 发票张数颜色显示 */
    handleRecognizeCountColor(unsubmittedPageCount, submittedPageCount, pageSize, isExistList) {
      if (isExistList && unsubmittedPageCount + submittedPageCount != pageSize) {
        return '#F62929';
      } else {
        return '#666666';
      }
    },
    /* 发票识别状态 */
    handleRecognizeStatusColor(status) {
      switch (status) {
        case 'SUCCESS':
          return '#4FB81B';
        case 'FAILURE':
          return '#F62929';
        case 'REPEAT':
          return '#D8870D';
        default:
          return '#666666';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.tabs_box {
  position: relative;
}
.select-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #e9e9e9;
  padding: 16px 24px;

  .select-items {
    display: inline-flex;

    div {
      color: #3d94ff;
      margin-right: 20px;
      font-weight: 500;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      line-height: 22px;

      img {
        width: 20px;
        height: 20px;
        vertical-align: sub;
        margin-right: 4px;
      }
    }

    div:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .select-title span:before {
    content: '|';
    display: inline-block;
    font-size: 16px;
    margin-right: 10px;
    background-color: #333;
  }
}

.content-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100% - 180px);

  .main-top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 12px;
    cursor: pointer;

    .top-left {
      display: flex;
      justify-content: flex-start;
    }

    span {
      font-size: 16px;
      color: #666666;
    }
  }

  .content-table {
    .operate-button {
      display: flex;
      justify-content: center;

      .el-button {
        padding: 0;
        margin-right: 10px;
      }
    }
  }
}

.invoice-update {
  margin: 24px;
  .el-row {
    margin-bottom: 20px;

    &:first-child {
      margin-top: 16px;
    }
  }

  .el-col {
    text-align: left;
    border-radius: 4px;
  }
}

::v-deep .el-dialog__body {
  padding: 24px 20px;
  color: #333333;
  font-size: 14px;
  word-break: break-all;
}

::v-deep .el-form-item__error {
  padding-top: 0;
}

::v-deep .el-dialog__footer {
  padding: 0 20px 20px;
  text-align: right;
  box-sizing: border-box;
}
.submit_Allow_style {
  ::v-deep .el-dialog__footer {
    text-align: center;
    padding: 10px 20px 20px;
  }
  ::v-deep .el-dialog__header {
    padding: 24px 24px 10px 24px;
  }
}
.imgSet {
  width: 20px;
  height: 20px;
  margin: 0 2px 0 30px;
}
// .train21No {
// margin-left: -59px;

// .el-input {
//  width: 150px;
// }
// }
.exist-data {
  display: flex;
  flex-wrap: wrap;
  padding-left: 30px;

  .show-container {
    position: relative;

    img {
      width: 20px;
      height: 20px;
      margin: 20px 100px;
      position: absolute;
      z-index: 2;
    }

    .show-invoice {
      width: 260px;
      height: 160px;
      border: 2px solid #cccccc;
      margin: 12px 15px;
      z-index: 1;
      .xml_con_box {
        padding: 60px 0 0 20px;
        text-align: left;
      }
      .el-image {
        width: 260px;
        height: 160px;
      }

      .image-mask {
        display: none;
      }
    }
  }
}
.add___ {
  width: 260px;
  height: 160px;
  border: 2px solid #cccccc;
  margin: 12px 15px;
  z-index: 1;
  line-height: 160px;
}
</style>
<style lang="scss">
.local_collect_invoice_view {
  position: relative;
  .el-select__tags {
    top: 10%;
    width: auto !important;
    transform: none;
  }
}
.kp_title {
  text-align: left;
  font-size: 14px;
  margin-bottom: 10px;
}
</style>
<style lang="scss">
.incvoice_open_goods_list {
  table {
    width: 100%;
  }
}
.child_table {
  background: #fafafa !important;
  &.el-table th.el-table__cell,
  &.el-table tr.el-table__row {
    background: #fafafa !important;
  }
}
.el-table__expand-icon:hover {
  color: #1890ff !important;
}
// .no_children {
//   .el-table__expand-column {
//     display: none !important;
//   }
// }
</style>
